<template>
  <div>
    <div class="container-fluid bg-content">
      <v-row>
        <v-col>
          <div class="content-button-flex-end">
            <v-btn
              @click="dialogAddDocument = true"
              class="button-yellow mon-bold"
              elevation="0"
            >
              {{ texts.warehouse.attachments.buttonAddDocument }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="images.length > 0">
        <v-col
          cols="12"
          sm="12"
        >
          <div v-if="isResponsive">
            <div
              v-for="(item, index) in images"
              :key="index"
              class="content-card"
              style="padding: 10px;"
            >
              <div class="display-flex align-items-center">
                <p class="mon-bold mb-0">{{ texts.warehouse.tableAttachments.headers[0] }}</p>
                <v-spacer />
                <p class="mon-regular mb-0">{{ item.sName }}</p>
              </div>
              <div class="display-flex align-items-center mt-4">
                <p class="mon-bold mb-0">{{ texts.warehouse.tableAttachments.headers[1] }}</p>
                <v-spacer />
                <p
                  class="mon-regular mb-0"
                  @click="redirectURL(item.sFileUrl)"
                  style="width: 250px; overflow: hidden; text-overflow: ellipsis; color: #0971fb;"
                >
                  {{ item.sFileKey }}
                </p>
              </div>
              <div>
                <v-btn
                  class="button-tertiary mt-5 mb-2"
                  @click="setDialogDelete(item.sCustomerWarehousesFileId)"
                  style="width: 100%;"
                  elevation="0"
                >
                  {{
                    (selectLanguage == 'sp')
                    ? 'Eliminar'
                    : 'Delete'
                  }}
                </v-btn>
              </div>
            </div>
            <div class="display-flex align-items-center justify-content-center mt-9">
              <div class="pagination-styles">
                <v-pagination
                  v-model="iCurrentPage"
                  :length="lengthPage"
                  color="#FFC556"
                  next-icon="mdi-menu-right"
                  prev-icon="mdi-menu-left"
                ></v-pagination>
              </div>
            </div>
          </div>
          <div
            v-else
            class="content-card mt-5 mb-9"
          >
            <div class="pa-2">
              <div>
                <div>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th
                            v-for="(item, index) in texts.warehouse.tableAttachments.headers"
                            :key="index"
                            :class="
                            item == 'Acciones' || item == 'Actions'
                              ? 'text-right text-header-title mon-bold'
                              : 'text-header-title mon-bold'
                          "
                          >
                            {{ item }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in images"
                          :key="index"
                        >
                          <td class="mon-regular">{{ item.sName }}</td>
                          <td
                            class="text-title-file mon-regular"
                            @click="redirectURL(item.sFileUrl)"
                          >
                            {{ item.sFileKey }}
                          </td>
                          <td :class="
                            texts.warehouse.tableAttachments.headers[2] ==
                              'Acciones' ||
                            texts.warehouse.tableAttachments.headers[2] ==
                              'Actions'
                              ? 'text-right'
                              : ''
                          ">
                            <v-btn
                              @click="
                              setDialogDelete(item.sCustomerWarehousesFileId)
                            "
                              icon
                            >
                              <v-icon
                                color="#D8686A"
                                size="18px"
                              >
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
                <div class="display-flex align-items-center justify-content-center mt-9">
                  <div class="pagination-styles">
                    <v-pagination
                      v-model="iCurrentPage"
                      :length="lengthPage"
                      color="#FFC556"
                      next-icon="mdi-menu-right"
                      prev-icon="mdi-menu-left"
                    ></v-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br /><br /><br /><br /><br /><br /><br /><br /><br />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <div class="
                pa-9
                display-flex
                align-items-center
                justify-content-center
              ">
            <p class="text-documents mon-regular">
              {{ texts.warehouse.attachments.textNoDocuments }}
            </p>
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- add documents dialog -->
    <v-dialog
      persistent
      v-model="dialogAddDocument"
      :width="screenWidth + '%'"
    >
      <div class="container-dialog">
        <close-dialog-component @closeDialog="closeDialog" />
        <p class="text-title-dialog mon-bold">
          {{ texts.warehouse.dialogAddDocument.textTitle }}
        </p>
        <div>
          <v-text-field
            v-model="documentName"
            type="text"
            :label="texts.warehouse.dialogAddDocument.textDocumentName"
            outlined
            color="#000000"
            dense
            class="global-inputs mon-regular"
            :placeholder="texts.warehouse.dialogAddDocument.textDocumentName"
            persistent-placeholder
          ></v-text-field>
          <v-btn
            v-if="documentLabel == ''"
            @click="$refs.inputUpload.click()"
            class="button-add-document mon-regular mt-5"
            elevation="0"
          >
            {{ texts.warehouse.dialogAddDocument.textDocumentLabelFile }}
            <v-icon
              class="ml-1"
              color="#707070"
              size="16px"
            >
              mdi-tray-arrow-up
            </v-icon>
            <input
              ref="inputUpload"
              style="display: none"
              type="file"
              size="60"
              accept=".doc, .docx, .pdf"
              @change="uploadFilesWarehouse"
            />
          </v-btn>
          <div
            v-else
            class="
              content-file
              display-flex
              align-items-center
              justify-content-center
              mt-5
            "
          >
            <p class="text-file mon-regular">{{ documentLabel }}</p>
            <v-btn
              @click="deleteDocument"
              icon
            >
              <v-icon
                class="ml-2"
                color="#D8686A"
                size="18px"
              >
                mdi-delete
              </v-icon>
            </v-btn>
          </div>
        </div>
        <div
          class="mt-9 mb-5"
          style="border-bottom: 1px solid #cecece"
        />
        <div
          v-if="isResponsive"
          class="mt-5"
        >
          <v-btn
            :loading="bLoading"
            :disabled="!validateForm"
            @click="sendFileWarehouse"
            elevation="0"
            class="button-primary mon-bold"
            style="width: 100%;"
          >
            {{ texts.warehouse.dialogAddDocument.textButtonAdd }}
          </v-btn>
          <v-btn
            @click="closeDialog"
            elevation="0"
            class="button-secondary mon-regular mt-4"
            style="width: 100%;"
          >
            {{ texts.warehouse.dialogAddDocument.textButtonClose }}
          </v-btn>
        </div>
        <div
          v-else
          class="display-flex align-items-center justify-content-flex-end"
        >
          <v-btn
            @click="closeDialog"
            elevation="0"
            class="button-transparent mon-regular"
          >
            {{ texts.warehouse.dialogAddDocument.textButtonClose }}
          </v-btn>
          <v-btn
            :loading="bLoading"
            :disabled="!validateForm"
            @click="sendFileWarehouse"
            elevation="0"
            class="button-yellow mon-bold ml-4"
          >
            {{ texts.warehouse.dialogAddDocument.textButtonAdd }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "WarehouseDetailContentTabTwoLayout",
  props: {
    texts: {
      type: Object,
    },
    arr: {
      type: Object,
    },
  },
  data() {
    return {
      //VARIABLES
      images: [],
      lengthPage: 0,
      iCurrentPage: 1,
      screenWidth: 0,
      dialogAddDocument: false,
      documentName: "",
      documentLabel: "",
      documentFile: null,
      bLoading: false,
      isResponsive: false,
    };
  },
  beforeMount() {
    this.getFiles();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    closeDialog: function () {
      this.dialogAddDocument = false;
      this.documentName = "";
      this.documentLabel = "";
      this.documentFile = null;
    },
    deleteDocument: function () {
      this.documentLabel = "";
      this.documentFile = null;
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 35;
      } else {
        this.screenWidth = 100;
      }

      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    setDialogDelete: function (id) {
      this.$store.commit("setDialogDelete", {
        active: true,
        title: this.texts.warehouse.deleteTextDocument,
        api: `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$store.state.sEnterpriseId}/warehouses/${this.arr.sCustomerWarehouseId}/files/${id}`,
        redirect: "",
      });
    },
    redirectURL: function (url) {
      window.open(url, "_blank");
    },
    getFiles: function () {
      const payload = {};

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$store.state.sEnterpriseId}/warehouses/${this.arr.sCustomerWarehouseId}/files?iPageNumber=${this.iCurrentPage}&iItemsPerPage=10`,
        config,
        payload
      )
        .then((response) => {
          this.images = response.data.results;
          this.lengthPage = response.data.iNumPages;
          this.iCurrentPage = response.data.iCurrentPage;
          this.$store.commit("refresher", false);
        })
        .catch((err) => {
          this.mixError(err.response.data.message);
        });
    },
    uploadFilesWarehouse: function (e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        if (files[0].name.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.documentLabel = files[0].name;
          this.documentFile = files[0];
          // this.sendFileWarehouse(files[0], files[0].name);
        });
      }
    },
    sendFileWarehouse: function () {
      this.bLoading = true;

      const payload = { sName: this.documentName };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$store.state.sEnterpriseId}/warehouses/${this.arr.sCustomerWarehouseId}/files`,
        payload,
        config
      )
        .then((response) => {
          this.sendImage(response.data.results.sCustomerWarehousesFileId);
        })
        .catch((err) => {
          this.bLoading = false;
          this.mixError(err.response.data.message);
        });
    },
    sendImage(id) {
      const self = this;

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      var form = new FormData();
      form.append("file", this.documentFile);

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$store.state.sEnterpriseId}/warehouses/${this.arr.sCustomerWarehouseId}/files/${id}`,
        form,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.mixSuccess(response.data.message);
          this.closeDialog();
          this.$store.commit("refresher", true);
          this.getFiles();
        })
        .catch((err) => {
          this.bLoading = false;
          this.mixError(err.response.data.message);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    refresh: function () {
      return this.$store.state.refresh;
    },
    validateForm: function () {
      return this.documentName !== "" && this.documentLabel !== "";
    },
  },
  watch: {
    arr: function () {
      if (this.arr) {
        this.getFiles();
      }
    },
    refresh: function () {
      this.getFiles();
    },
    iCurrentPage: function () {
      this.getFiles();
    },
  },
};
</script>

<style scoped>
.button-yellow:disabled {
  opacity: 0.5;
  cursor: no-drop !important;
  pointer-events: all !important;
}

.content-file {
  border: 1px dashed #707070;
  border-radius: 10px;
  padding: 0px;
}

.text-file {
  text-align: center;
  font-size: 12px;
  letter-spacing: 0px;
  color: #707070;
  margin-bottom: 0px;
}

.button-add-document {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px dashed #707070;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 12px;
  letter-spacing: 0px;
  color: #707070;
  text-transform: initial;
  width: 100%;
}

.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 25px;
  position: relative;
}

.text-title-dialog {
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 28px;
}

.text-title-file {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #0971fb;
  text-decoration: underline;
  cursor: pointer;
  opacity: 1;
  margin-bottom: 0px;
}

.text-title-file:hover {
  color: #0971fb;
  text-decoration: underline;
  cursor: pointer;
}

.text-documents {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-title-table {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.separator-line {
  border: 1px solid #cecece;
}

.content-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
}

.content-button-flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button-transparent {
  background-color: #ffffff !important;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 10px;
  opacity: 1;
  font-size: 14px;
  height: 40px !important;
  padding-left: 25px;
  padding-right: 25px;
  text-transform: initial;
}

.button-yellow {
  background: transparent linear-gradient(254deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  font-size: 14px;
  height: 40px !important;
  padding-left: 25px;
  padding-right: 25px;
  text-transform: initial;
}
</style>